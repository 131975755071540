import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import ArticleSection from '../../components/ArticleSection';
import Header from '../../components/Archive/Header';
import Newsletter from '../../components/Newsletter';
import Pagination from '../../components/Pagination';
import GeneralLayout from '../../layouts/index';

const PersonThoughtTemplate = ({ transition, data, pageContext }) => {
  const { group, index, pageCount, pathPrefix } = pageContext;
  const { contentfulPerson, contentfulNewsletter, site } = data;

  return (
    <GeneralLayout>
        <Theme theme="blue" transition={transition}>
          <Seo
            siteData={site}
            defaultTitle={`${contentfulPerson.name} Thoughts - Emergence`}
            pathName={`/people/${contentfulPerson.slug}/thoughts/${index}`}
          />
          <Header overline="Thoughts" title={contentfulPerson.name} />
          {group && <ArticleSection articles={group} />}
          {pageCount > 1 && <Pagination index={index} pageCount={pageCount} pathPrefix={pathPrefix} />}
          <Newsletter content={contentfulNewsletter} />
        </Theme>
    </GeneralLayout>
  );
};

PersonThoughtTemplate.propTypes = {
  transition: PropTypes.object,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export default PersonThoughtTemplate;

export const pageQuery = graphql`
  query personThoughtQuery($id: String!) {
    contentfulPerson(id: { eq: $id }) {
      name
      title
      slug
      ...PersonImagery
    }
    ...NewsletterQuery
    ...SiteQuery
  }
`;
